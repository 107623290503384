<template>
  <v-container>
    <v-row justify="center">
      <div
        style="
          width: 300px;
          background-color: #ffe57d;
          border-radius: 10px;
          text-align: left;
          font-family: notosansregular;
        "
      >
        <p style="margin: auto; padding: 10px">{{ mention }}</p>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    _mention: {
      type: String,
      required: true,
    },
  },
  computed: {
    mention: {
      get() {
        return this._mention;
      },
      set() {
        return this._mention;
      },
    },
  },
};
</script>

<style>
</style>