<template>
  <div class="errordiv">
    <div style="height: 30px"></div>
    <div class="errorimg"></div>
    <div class="errortext">
      <p v-for="(j, i) in errortext" :key="i" style="padding: 3px">
        {{ errortext[i] }}
      </p>
    </div>
    <v-btn
      outlined
      @click="ok()"
      width="120"
      height="50"
      variant="outlined"
      style="
        background-color: #3d90ef;
        color: white;
        font-size: 20px;
        border-radius: 30px;
        font-family: notosansregular;
        margin-left: 205px;
        position: absolute;
      "
      >확인</v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    errortext: {
      type: Array,
    },
  },
  methods: {
    ok() {
      const event = new CustomEvent("error", {
        detail: {
          status: false,
          text: [],
        },
      });

      window.dispatchEvent(event);
    },
  },
};
</script>

<style scoped>
.errordiv {
  width: 550px;
  height: 360px;
  background-color: white;
  font-family: notosanslight;
  font-weight: bold;
  border-radius: 15px;
}

.errorimg {
  background-image: url("../../assets/images/iconx.png");
  background-size: cover;
  width: 65px;
  height: 65px;
  margin-left: 235px;
}

.errortext {
  left: 50%;
  top: 50%;
  font-size: 22px;
  width: 550px;
  text-align: center;
  color: #f23a3a;
  margin-top: 50px;
}

.okbtn {
  background-color: #3d90ef;
  color: white;
  font-size: 20px;
  border-radius: 30px;
  font-family: notosansregular;
}
</style>