<template>
  <div class="background">
    <div style="transform: scale(0.9)">
      <div
        style="
          background-color: white;
          border: 1px solid black;
          border-radius: 15px;
          float: left;
        "
      >
        <div>
          <div style="padding: 20px; padding-bottom: 0px">
            <div style="width: 300px">
              <v-select
                placeholder="프롬프트 세션을 선택해주세요"
                outlined
                dense
                v-model="rootSession"
                @change="rootChange()"
                :items="sessionList"
                :item-text="`subjectSentence`"
                :item-value="`rootKey`"
              ></v-select>
            </div>
            <div style="width: 300px">
              <v-select
                placeholder="대화 세션을 선택해주세요"
                outlined
                dense
                v-model="childSession"
                @change="childChange()"
                :items="rootChilds"
              ></v-select>
            </div>
          </div>
          <div
            v-show="rootPrompt !== ''"
            style="
              float: right;
              margin-top: 25px;
              font-family: notosansregular;
              font-size: 15px;
              padding: 20px;
            "
          >
            <div style="margin-right: 40px; width: 180px; float: left">
              <p>학년 / 학기 : {{ rootPrompt.gradeInfo }}</p>
              <p>과목 : {{ rootPrompt.subject }}</p>
              <p>일시 : {{ promptDate }}</p>
            </div>
            <div style="float: left; width: ">
              <p>대단원 : {{ rootPrompt.bigSubject }}</p>
              <p>중단원 : {{ rootPrompt.middleSubject }}</p>
              <p>소단원 : {{ rootPrompt.smallSubject }}</p>
            </div>
          </div>
        </div>
      </div>
      <v-card
        outlined
        width="800"
        height="750"
        style="
          border-radius: 15px;
          border: 1px solid rgb(240, 240, 240);
          float: left;
          margin-left: 10px;
        "
      >
        <header>
          <v-card-title
            style="
              background-color: #1976d2;
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
              color: white;
            "
          >
            AI Tutor Saved Session
          </v-card-title>
        </header>
        <v-row style="margin-top: 10px" justify="center">
          <v-card
            id="scroll-target"
            outlined
            width="780"
            height="600"
            style="overflow-y: scroll; overflow-x: hidden"
          >
            <div
              v-for="(talk, i) in talk_session"
              :key="i"
              style="width: 700px; padding: 15px"
            >
              <usertalk
                v-if="talk_session[i].role == 'user'"
                style="margin-left: 220px; margin-top: 15px"
                :_mention="talk_session[i].content"
              ></usertalk>
              <aitalk
                v-if="talk_session[i].role == 'assistant'"
                style="margin-top: 15px"
                :_mention="talk_session[i].content"
              ></aitalk>
            </div>
          </v-card>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import usertalk from "../components/Usertalk.vue";
import aitalk from "../components/AItalk3.vue";
import { getSessionList, getSaveSession } from "../api/api-list";

export default {
  name: "HelloWorld",
  data() {
    return {
      mention: "",
      default_mention: "gdfndgng",
      talk_session: [],
      talk_session_temp: [],
      talk_prompt: "",
      hint_prompt: "",
      scrollTarget: null,
      conversation_list: [],
      topics: [],
      tutorTitle: "",
      tutorPrompt: "",
      session_id: "",
      session_date: "",
      endSentence: "",
      isSend: true,
      isTest: false,
      sessionList: [],
      rootSession: "",
      rootPrompt: "",
      rootChilds: [],
      targetChild: [],
      childSession: "",
      promptDate: "",
    };
  },
  async created() {
    this.sessionList = (await getSessionList()).data;
  },
  mounted() {},
  methods: {
    rootChange() {
      this.talk_session = [];
      this.talk_session_temp = [];
      this.sessionInfo = this.sessionList.filter(
        (item) => item.rootKey === this.rootSession
      )[0];
      this.rootPrompt = this.sessionInfo.promptData;
      this.rootChilds = this.sessionInfo.sessions;
      this.promptDate = this.prettyDate(this.sessionInfo.date);
    },
    async childChange() {
      const res = (await getSaveSession(this.rootSession, this.childSession))
        .data;

      this.talk_session = res;
      this.talk_session_temp = res;
    },
    prettyDate(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
  components: {
    usertalk,
    aitalk,
  },
};
</script>

<style>
.background {
  background-color: beige;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableOn {
  margin-left: -20px;
}

.tableOff {
  margin-left: -170px;
}
</style>
