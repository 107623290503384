import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './action'
import * as mutations from './mutation'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    session: []
  },
  getters: {},
  actions,
  mutations,
  modules: {}
})
