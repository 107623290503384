<template>
  <v-app style="min-width: 200px">
    <v-main>
      <router-view></router-view>
      <v-dialog width="550" v-model="errormodal">
        <error :errortext="errortext"></error>
      </v-dialog>
      <v-dialog persistent v-model="successmodal">
        <success :successtext="successtext"></success>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import error from "./components/common/errorPopup.vue";
import success from "./components/common/successPopup.vue";

export default {
  created() {
    window.addEventListener("error", this.handleError);
    window.addEventListener("success", this.handleSuccess);
  },
  data() {
    return {
      errormodal: false,
      errortext: [],
      successmodal: false,
      successtext: [],
    };
  },
  components: {
    error,
    success,
  },
  methods: {
    handleError(event) {
      this.successmodal = false;
      const data = event.detail;
      if (data.status) {
        this.errortext = data.text;
        this.errormodal = true;
      } else {
        this.errormodal = false;
        this.errortext = [];
      }
    },
    handleSuccess(event) {
      this.errormodal = false;
      const data = event.detail;
      if (data.status) {
        this.successtext = data.text;
        this.successmodal = true;
      } else {
        this.successmodal = false;
        this.successtext = [];
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: notosans;
  src: url("./assets/font/NotoSansKR-Bold.woff2");
}

@font-face {
  font-family: notosansmedium;
  src: url("./assets/font/NotoSansKR-Medium.woff2");
}

@font-face {
  font-family: notosanslight;
  src: url("./assets/font/NotoSansKR-Light.woff2");
}

@font-face {
  font-family: notosansregular;
  src: url("./assets/font/NotoSansKR-Regular.woff2");
}
</style>
