import axios from 'axios'

const createInstance = () => {
  return axios.create({
    baseURL: 'https://test-tutor.argong.ai'
  })
}

const instance = createInstance()

export { instance }
