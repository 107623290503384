<template>
  <v-container>
    <v-row justify="center">
      <div
        style="
          width: 300px;
          background-color: #b2eeff;
          border-radius: 10px;
          text-align: left;
          font-family: notosansregular;
          margin-left: -330px;
        "
      >
        <p style="margin: auto; padding: 10px">{{ mention }}</p>
      </div>
      <!-- <div v-show="isTable === true">
        <div
          style="
            width: 600px;
            background-color: peachpuff;
            border-radius: 10px;
            text-align: left;
            font-family: notosansregular;
            padding: 10px;
          "
          v-html="mention"
        ></div>
      </div> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {},
  data() {
    return {
      divset: false,
      hint: "",
      index: 0,
    };
  },
  props: {
    _mention: {
      type: String,
      required: true,
    },
  },
  computed: {
    mention: {
      get() {
        return this._mention;
      },
      set() {
        return this._mention;
      },
    },
  },
  methods: {},
};
</script>

<style>
.table {
  width: 600px;
  margin-left: 100px;
}

.notable {
  width: 300px;
}
</style>